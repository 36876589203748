import axios from "axios";
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  // ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  // CASH_ON_DELIVERY_ORDER_REQUEST,
  // CASH_ON_DELIVERY_ORDER_SUCCESS,
  // CASH_ON_DELIVERY_ORDER_FAIL,
  MOMO_REMOVE_SHIPPING_ADDRESS,
  // COD_REMOVE_SHIPPING_ADDRESS,
  // ORDER_DELIVER_REQUEST,
  // ORDER_DELIVER_SUCCESS,
  // ORDER_DELIVER_FAIL,
  START_LOADING,
  END_LOADING,
  PROCESS_ORDER_CREATE_REQUEST,
  PROCESS_ORDER_CREATE_SUCCESS,
  PROCESS_ORDER_CREATE_FAIL,
  // EDIT_ORDER_STATUS,
  // CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER,
  // CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER,
  // CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER,
  VENDOR_ORDER_DETAILS_SUCCESS,
  VENDOR_ORDER_DETAILS_FAIL,
  UPDATE_DELIVERY_STATUS,
  UPDATE_DELIVERY_STATUS_COMPLETE,
  MARK_ORDER_PAID,
  MARK_ORDER_PAID_FAIL,
  UPDATE_OVERALL_ORDER_DELIVERY_STATUS,
  SET_ORDER_DATA_BY_RIDER,
} from "../constants/webOrderConstants";
import {
  CART_CLEAR_ITEMS,
  // CART_CLEAR_SHIPPING_ADDRESS,
} from "../constants/cartConstants";

import { toast } from "react-toastify";
import * as api from "../api/index.js";
import { updateDeliveryStatusSuccess } from "../reducers/actions";

//CREATE COD ORDER
export const createOrder = (order) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createOrderEndPoint(order);

    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });
    localStorage.removeItem("cartItems");
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

//TO DELETE
// export const createOrder = (order) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: ORDER_CREATE_REQUEST });

//     const { data } = await axios.post(`/weborders/`, order);
//     //   const { data } = await api.fetchCreateOrder(order)

//     dispatch({
//       type: ORDER_CREATE_SUCCESS,
//       payload: data,
//     });
//     dispatch({
//       type: CART_CLEAR_ITEMS,
//       payload: data,
//     });
//     localStorage.removeItem("cartItems");
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({
//       type: ORDER_CREATE_FAIL,
//       payload: message,
//     });
//   }
// };

export const processCreateOrder = (user_id) => async (dispatch) => {
  try {
    // console.log(user_id, "CRYYYY");
    dispatch({ type: PROCESS_ORDER_CREATE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/cod/${user_id}`
    );
    // const { data } = await axios.post(`/weborders/cash`, order);
    //   const { data } = await api.fetchCreateOrder(order)

    dispatch({
      type: PROCESS_ORDER_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });
    localStorage.removeItem("cartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PROCESS_ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

//Not in use
// export const getCashOnDeliveryOrders = () => async (dispatch) => {
//   try {
//     dispatch({ type: CASH_ON_DELIVERY_ORDER_REQUEST });

//     const { data } = await axios.get(`/weborders/all`);

//     dispatch({
//       type: CASH_ON_DELIVERY_ORDER_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: CASH_ON_DELIVERY_ORDER_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

// export const getCashOnDeliveryOrdersNoLoader = () => async (dispatch) => {
//   try {
//     dispatch({ type: CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER });

//     const { data } = await axios.get(`/weborders/all`);

//     dispatch({
//       type: CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//     console.log(error);
//   }
// };

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    // const { data } = await axios.get(`/weborders/${id}`);
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/weborders/${id}`
    );

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// For Tracking rider declined orders
export const setOrderDataByRider = (orderData) => ({
  type: SET_ORDER_DATA_BY_RIDER,
  payload: orderData,
});

export const getOrderDetailsToProcess = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    // const { data } = await axios.get(`https://lusuku.shop/weborders/${id}`);
    // const { data } = await axios.get(`/weborders/${orderId}`);
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/weborders/${orderId}`
    );
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Get Vendor Orders
export const getVendorOrderDetailsToProcess =
  (orderId, vendorId) => async (dispatch) => {
    dispatch({ type: START_LOADING });

    try {
      const { data } = await api.getVendorOrderDetailsToProcessApi(
        orderId,
        vendorId
      );
      dispatch({
        type: VENDOR_ORDER_DETAILS_SUCCESS,
        payload: data,
      });

      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
// To Delete
// export const getVendorOrderDetailsToProcessX =
//   (orderId, vendorId) => async (dispatch) => {
//     try {
//       dispatch({ type: START_LOADING });

//       const { data } = await axios.get(
//         `/weborders/vendor/${orderId}/${vendorId}`
//       );

//       dispatch({
//         type: VENDOR_ORDER_DETAILS_SUCCESS,
//         payload: data,
//       });

//       dispatch({ type: END_LOADING });
//     } catch (error) {
//       console.error("Error in getVendorOrderDetailsToProcess:", error);

//       const errorMessage =
//         error.response && error.response.data && error.response.data.message
//           ? error.response.data.message
//           : error.message;

//       dispatch({
//         type: VENDOR_ORDER_DETAILS_FAIL,
//         payload: errorMessage,
//       });
//     }
//   };

export const fetchUpdatedOrderDetails =
  (orderId, vendorId) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      // Wait for both requests to complete
      const [orderDetailsResponse, vendorOrderDetailsResponse] =
        await Promise.all([
          axios.get(`${process.env.REACT_APP_SERVER_URL}/weborders/${orderId}`),
          axios.get(
            `${process.env.REACT_APP_SERVER_URL}/weborders/vendor/${orderId}/${vendorId}`
          ),
        ]);

      // Dispatch both success actions
      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: orderDetailsResponse.data,
      });

      dispatch({
        type: VENDOR_ORDER_DETAILS_SUCCESS,
        payload: vendorOrderDetailsResponse.data,
      });

      dispatch({ type: END_LOADING });
    } catch (error) {
      // Handle errors for both requests
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload: errorMessage,
      });

      dispatch({
        type: VENDOR_ORDER_DETAILS_FAIL,
        payload: errorMessage,
      });
    }
  };

//UPDATING ORDER DELIVERY STATUS

//Update Dispatch Action for updating Delivery Status of individual order items
export const updateDeliveryStatus =
  (orderId, itemId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.updateDeliveryStatusApi(
        orderId,
        itemId,
        delivery_status,
        {
          delivery_status,
        }
      );

      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success(data?.message);
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation

      // Check if all items have been delivered
      if (
        data?.order?.orderItems.every(
          (item) =>
            item.delivery_status === "DELIVERED" ||
            item.delivery_status === "REJECTED"
        )
      ) {
        dispatch({
          type: UPDATE_OVERALL_ORDER_DELIVERY_STATUS,
          payload: { ...data.order, delivery_status: "DELIVERED" },
        });
      }
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error updating Delivery Status!");
      toast.error(
        err?.data?.message || "An error occurred while updating delivery status"
      );
    }
  };

export const updateRestaurantOrderDeliveryStatus =
  (restaurantOrderId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.updateRestaurantOrderDeliveryStatusApi(
        restaurantOrderId,
        delivery_status,
        {
          delivery_status,
        }
      );

      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success(data?.message);
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error updating Delivery Status!");
      toast.error(
        err?.data?.message || "An error occurred while updating delivery status"
      );
    }
  };

// Update package order delivery status
export const updatePackageOrderDeliveryStatus =
  (packageOrderId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.updatePackageOrderDeliveryStatusApi(
        packageOrderId,
        delivery_status,
        {
          delivery_status,
        }
      );

      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success(data?.message);
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error updating Delivery Status!");
      toast.error(
        err?.data?.message || "An error occurred while updating delivery status"
      );
    }
  };

// Update overall order Delivery status
export const updateOverallOrderDeliveryStatus =
  (orderId, itemUpdate) => async (dispatch) => {
    try {
      const { data } = await api.updateOverallOrderDeliveryStatusApi(
        orderId,
        itemUpdate
      );

      dispatch({ type: UPDATE_OVERALL_ORDER_DELIVERY_STATUS, payload: data });
      toast.success(data?.message);
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error updating Overall Order Delivery Status!");
      toast.error(
        err?.data?.message ||
          "An error occurred while updating overall order delivery status"
      );
    }
  };
// ASSIGNING ORDER TO DELIVERY AGENT // This includes assigning individual order items to a delivery agent
export const assignOrderToDeliveryAgent =
  (orderId, riderId, itemId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.assignOrderToDeliveryAgentApi(
        orderId,
        riderId,
        itemId,
        delivery_status
      );

      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success("Item Successfully Assigned to DA!");
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error assigning Order to a DA!");
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while Assigning order to DA!"
      );
    }
  };

export const assignRestaurantOrderToDeliveryAgent =
  (restaurantOrderId, riderId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.assignRestaurantOrderToDeliveryAgentApi(
        restaurantOrderId,
        riderId,
        delivery_status
      );

      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success("Order Successfully Assigned to Delivery Agent!");
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error assigning Order to a Delivery Agent!");
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while assigning order to Delivery Agent!"
      );
    }
  };

// Assign Package Order to Delivery Agent
export const assignPackageOrderToDeliveryAgent =
  (orderId, riderId, delivery_status) => async (dispatch) => {
    try {
      const { data } = await api.assignPackageOrderToDeliveryAgentApi(
        orderId,
        riderId,
        delivery_status
      );
      dispatch({ type: UPDATE_DELIVERY_STATUS, payload: data });
      toast.success("Order Successfully Assigned to Delivery Agent!");
      dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE }); // after successful operation
      dispatch({ type: END_LOADING });
    } catch (err) {
      console.log(err, "error assigning Order to a Delivery Agent!");
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while assigning order to Delivery Agent!"
      );
    }
  };

// MARK ORDER AS PAID
export const markOrderAsPaid = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.markOrderAsPaidApi(orderId);

    dispatch({ type: MARK_ORDER_PAID, payload: data });
    toast.success(data?.message);

    dispatch({ type: UPDATE_DELIVERY_STATUS_COMPLETE });
    dispatch({ type: END_LOADING });
  } catch (err) {
    console.log(err, "err with marking order payment!");
    dispatch({ type: MARK_ORDER_PAID_FAIL, payload: err.toString() });
    dispatch({ type: END_LOADING });
  }
};
// To Delete
// export const markOrderAsPaidX = (orderId, callback) => {
//   return function (dispatch) {
//     axios
//       .put(`/weborders/${orderId}/paid`)
//       .then((res) => {
//         toast.success(res.data.message);
//         dispatch(getOrderDetailsToProcess(orderId));
//         if (callback) {
//           callback();
//         }
//       })
//       .catch((err) => {
//         console.log(err, "err");
//         toast.error(err);
//       });
//   };
// };

export const payOrder = (orderId, paymentResult) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_PAY_REQUEST });

    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/weborders/${orderId}/pay`,
      paymentResult
    );
    dispatch({
      type: ORDER_PAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PAY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeShippingAddressAfterMomoPayment = (data) => (dispatch) => {
  dispatch({
    type: MOMO_REMOVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.removeItem("shippingAddress", JSON.stringify(data));
};

export const payMomoOrder = (order) => async (dispatch) => {
  let { client_id } = order;
  try {
    dispatch({ type: ORDER_CREATE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/momo/${client_id}`,
      order
    );
    //   const { data } = await api.fetchCreateOrder(order)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });
    localStorage.removeItem("cartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};
