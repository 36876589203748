import axios from "axios";
import { getUserFromLocalStorage } from "../utilities/user.js";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, //ProdEnv & NgrokEnv
});

API.interceptors.request.use((req) => {
  const profile = getUserFromLocalStorage();
  if (profile) {
    req.headers.Authorization = `Bearer ${profile.token}`;
  }
  return req;
});

export const createOrderEndPoint = (newCODOrder) =>
  API.post(`/weborders`, newCODOrder);

// Create Section
export const apiCreateSection = (sectionData, userId) =>
  API.post(`/restaurants/menu-sections/${userId}`, sectionData);

// Create Restaurant Menu Product Section
export const apiCreateRestaurantProduct = (restaurantProductData, userId) =>
  API.post(`/restaurants/restaurant-products/${userId}`, restaurantProductData);

// Fetch Restaurant product details
export const apiFetchRestaurantProductDetail = (productId) =>
  API.get(`/restaurants/restaurant-product-detail/${productId}`);

// Update Restaurant product
export const apiUpdateRestaurantProduct = (productId, updateData) =>
  API.put(`/restaurants/restaurant-product/${productId}`, updateData);

//Delete restaurant product
export const apiDeleteRestaurantProduct = (productId) =>
  API.delete(`/restaurants/del-restaurant-product/${productId}`);

// Delete Restaurant Ssection Along with it's products
export const apiDeleteSection = (sectionId) =>
  API.delete(`/restaurants/del-restaurant-section/${sectionId}`);

// Get Restaurants ||Conditionally get restaurants by vendorId if vendorId is provided
// export const apiFetchRestaurants = () => API.get('/restaurants');
// export const apiFetchRestaurants = (vendorId) => API.get(`/restaurants?vendorId=${vendorId}`);

//Reusing the same function to get all restaurants for superAdmin otherwise get restaurants by vendorId
export const apiFetchRestaurants = (vendorId, isSuperAdmin) => {
  let query = `/restaurants?vendorId=${vendorId}`;
  if (isSuperAdmin) {
    query += `&isSuperAdmin=${isSuperAdmin}`;
  }
  return API.get(query);
};

// Update Restaurant
export const apiUpdateRestaurant = (id, updatedData) =>
  API.put(`/restaurants/${id}`, updatedData);

// Delete Restaurant
export const apiDeleteRestaurant = (id) => API.delete(`/restaurants/${id}`);

// Get Restaurant Vendor Stats
export const apiFetchRestaurantVendorStat = (vendorId) =>
  API.get(`/weborders/restaurant-vendor/${vendorId}/stats`);

// Get Seller Stats (Both Retailers & Retailers)
export const apiFetchSellerStats = (vendorId) =>
  API.get(`/weborders/stats-seller/${vendorId}`);

// Get Restaurant Vendor Orders
export const fetchRestaurantVendorOrdersApi = (
  vendorId,
  page = 1,
  limit = 10
) =>
  API.get(`/weborders/restaurant-vendor-orders/${vendorId}/orders`, {
    params: { page, limit },
  });

// Get RetailerWholesaler Vendor Orders
export const fetchRetailerWholesalerVendorOrdersApi = (
  vendorId,
  page = 1,
  limit = 10
) =>
  API.get(`/weborders/retailer-wholesaler-vendor-orders/${vendorId}/orders`, {
    params: { page, limit },
  });

// Get retailerWholesaler Order Details
export const fetchRetailerWholesalerOrderDetailsApi = (orderId) =>
  API.get(`/weborders/retailer-wholesaler-order-details/${orderId}`);

//Update Order Details Status
export const updateRetailerWholesalerOrderStatusApi = (orderId, status) =>
  API.put(`/weborders/update_retailer_wholesaler_order_status/${orderId}`, {
    status,
  });

//Restaurant Profile
export const apiFetchRestaurantProfile = (vendorId) =>
  API.get(`/restaurants/restaurant-profile/${vendorId}`);
export const apiUpdateRestaurantProfile = (vendorId, formData) =>
  API.put(`/restaurants/restaurant-profile/${vendorId}`, formData);

// Retailer/Wholesaler Profile
export const apiFetchRetailerWholesalerProfile = (vendorId) =>
  API.get(`/rw/retailer-wholesaler-profile/${vendorId}`);

//Get retialers and wholesalers
export const apiFetchRetailersWholesalers = (adminId, isSuperAdmin) =>
  API.get("/rw/retailers-wholesalers", {
    params: { adminId, isSuperAdmin },
  });

export const apiUpdateRetailerWholesalerProfile = (vendorId, formData) =>
  API.put(`/rw/update-retailer-wholesaler-profile/${vendorId}`, formData);
// Get sections or conditionally get sections by vendorId if vendorId is provided
// export const apiFetchSections = () => API.get('/restaurants/menu-sections');
export const apiFetchSections = (vendorId) =>
  API.get(
    `/restaurants/menu-sections${vendorId ? `?vendorId=${vendorId}` : ""}`
  );

//Update Section
export const apiUpdateSection = (sectionId, updatedSectionData) =>
  API.patch(`/restaurants/menu-sections/${sectionId}`, updatedSectionData);
// // Create Product
// export const apiAddProduct = (productData, userId) => API.post(`/restaurants/menu-products/${userId}`, productData);

// Create Collection
// export const apiAddCollection = (collectionData) => API.post('/api/menu-collections', collectionData); //Not in use

//Get Listing
export const apifetchListing = (listing_id) =>
  API.get(`/posts/listing/${listing_id}`);

export const apifetchListings = (userId, page) =>
  API.get(`/posts/dashboard?userId=${userId}&page=${page}`);

// Get Deactivated Listings
export const apiGetDeactivatedListings = (user_id) =>
  API.get(`/posts/deactivated-listings/${user_id}`);

// Get Out of stock Listings
export const apiGetOuttaStockListings = (user_id) =>
  API.get(`/posts/out-of-stock-listings/${user_id}`);

//Get All Orders ~ With Pagination
export const fetchPaginatedOrders = (page) =>
  API.get(`/weborders/all_paginated?page=${page}`);

// Get Vendor Order Details to process
export const getVendorOrderDetailsToProcessApi = (orderId, vendorId) =>
  API.get(`/weborders/vendor/${orderId}/${vendorId}`);

// GET VENDOR'S ORDERS
export const fetchVendorOrders = (vendorId, page) =>
  API.get(`/weborders/vendor/${vendorId}?page=${page}`);

// Search Paginated Orders
export const searchOrders = (searchQuery) =>
  API.get(`/weborders/search_orders?searchQuery=${searchQuery.search}`);
// Search Vendor Paginated Orders
export const searchVendorOrders = (vendorId, searchQuery) =>
  API.get(
    `/weborders/search_vendor_orders/${vendorId}?searchQuery=${searchQuery}`
  );
// Get All Orders ~ Without Paginations
export const fetchListingsNoLimit = (userId, role) =>
  API.get(`/posts/all/${userId}/${role}`);

// Search Listings
export const fetchListingsBySearch = (searchQuery) =>
  API.get(
    `/posts/search/${searchQuery.user_id}?searchQuery=${
      searchQuery.search || "none"
    }`
  );

// Search Clients
export const fetchClientsBySearch = (searchQuery) =>
  API.get(
    `/user/search/${searchQuery.user_id}?searchQuery=${
      searchQuery.searchClient || "none"
    }`
  );

// Create Rider
export const createRiderApi = (riderData, creatorId) =>
  API.post(`/riders/${creatorId}`, riderData);

// Get all riders by Super Admin
export const getAllRidersApi = () => API.get("/riders/all-riders");

// Update Rider
export const updateRiderApi = (riderId, riderData) =>
  API.put(`/riders/${riderId}/update-by-admin`, riderData);

// Create Listing
export const apiCreateListing = (newListing, userId) =>
  API.post(`/posts/${userId}`, newListing);

export const apiCreatePromoListing = (newPromoListing, userId) =>
  API.post(`/posts/promo/${userId}`, newPromoListing);

export const apiUpdateListing = (listingId, updatedListing, userId) =>
  API.patch(`/posts/update/${listingId}/${userId}`, updatedListing);

// DEACTIVATE LISTING
export const apiDeactivateListing = (listingId, user_id) =>
  API.patch(`/posts/deactivate/${listingId}/${user_id}`);

// REACTIVATE LISTING
export const apiReactivateListing = (listingId, user_id) =>
  API.patch(`/posts/reactivate/${listingId}/${user_id}`);

// Delete Listing
export const deleteListing = (listingId, userId) =>
  API.delete(`/posts/${listingId}/${userId}`);

// GET ALL ORDERS NO LOADER
export const fetchOrders = () => API.get("/weborders/all");

// MOBILE END_POINTS
// export const fetchMobileOrder = (id) => API.get(`/mobile/${id}`);
export const fetchMobileUsers = (userId, role) =>
  API.get(`/mobileUser/all/${userId}/${role}`);

// Delete Staff || in-house user
export const deleteStaffUser = (staff_id, userId) =>
  API.delete(`/user/deleteStaff/${staff_id}/${userId}`);

// Delete mobile user
export const apiDeleteMobileUser = (client_id, userId) =>
  API.delete(`/user/deleteMobileUser/${client_id}/${userId}`);

export const fetchMobileUser = (id, userId, role) =>
  API.get(`/mobileUser/${id}/${userId}/${role}`);

export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);

// Create Client profile
export const createClientProfileApi = (formData, userId, role) =>
  API.post(`/user/client/${userId}`, formData);

// Create Staff Profile
export const createStaffProfileApi = (formData, userId) =>
  API.post(`/user/staff/${userId}`, formData);

export const fetchUsers = (userId, role) =>
  API.get(`/user/all/${userId}/${role}`);

// Find user by id ~ findUser
export const fetchUser = (editedUserId, staff_id, role) =>
  API.get(`/user/${editedUserId}/${staff_id}/${role}`);

// Update Agent Role ~ updateUserRole
export const updateUserRole = (userId, role, agentId) =>
  API.put(`/user/${userId}/${agentId}`, role);

export const submitVendorRegistration = (vendorFormData) =>
  API.post("/seller/register-seller", vendorFormData);

// Get sellers
export const fetchSellers = (staff_id) =>
  API.get(`/seller/get-sellers/${staff_id}`);

// Get seller Details
export const fetchSellerDetail = (seller_id) =>
  API.get(`/seller/get-seller/${seller_id}`);

// Delete seller
export const softDeleteSellerApi = (seller_id, staff_id) =>
  API.delete(`/seller/soft-delete-seller/${seller_id}/${staff_id}`);

// Delete seller
export const deleteSellerApi = (seller_id, staff_id) =>
  API.delete(`/seller/delete-seller/${seller_id}/${staff_id}`);

// Get all active categories
export const fetchActiveCategories = () => API.get("/category/categories");

// Get deactivated categories
export const fetchDeactivatedCategories = () =>
  API.get("/category/deactivated-categories");

// Get delivery zones
export const fetchDeliveryZones = () => API.get("/deliveryzone");

// Delete delivery zone
export const deleteZone = (zone_id) =>
  API.delete(`/deliveryzone/delete/${zone_id}`);

// Create delivery zone
export const createDeliveryZone = (zoneData) =>
  API.post("/deliveryzone/create", zoneData);

// Fetch delivery zone
export const fetchDeliveryZone = (zone_id) =>
  API.get(`/deliveryzone/${zone_id}`);

// Update delivery zone
export const patchDeliveryZone = (zone_id, updatedZoneData) =>
  API.patch(`/deliveryzone/update/${zone_id}`, updatedZoneData);

// Verify seller
export const verifySellerApi = (seller_id, staff_id, data) =>
  API.put(`/seller/verify-seller/${seller_id}/${staff_id}`, data);

// Reactivate seller
export const reactivateSellerApi = (seller_id, staff_id, data) =>
  API.put(`/seller/reactivate-seller/${seller_id}/${staff_id}`, data);

// Update Delivery Status (Including individual order items)
export const updateDeliveryStatusApi = (orderId, itemId, delivery_status) =>
  API.put(`/weborders/update_delivery_status/${orderId}/${itemId}`, {
    delivery_status,
  });

// Update Restaurant Delivery Status
export const updateRestaurantOrderDeliveryStatusApi = (
  restaurantOrderId,
  delivery_status
) =>
  API.put(
    `/weborders/update_restaurant_order_delivery_status/${restaurantOrderId}`,
    {
      delivery_status,
    }
  );

// Update Package Delivery Status
export const updatePackageOrderDeliveryStatusApi = (
  packageOrderId,
  delivery_status
) =>
  API.put(`/weborders/update_package_order_delivery_status/${packageOrderId}`, {
    delivery_status,
  });

// Update Overall Delivery Status
export const updateOverallOrderDeliveryStatusApi = (orderId, itemUpdate) =>
  API.put(`/weborders/update_overallOrder_delivery_status/${orderId}`, {
    itemUpdate,
  });

// Assign Individual Order Items to Delivery Agent
export const assignOrderToDeliveryAgentApi = (
  orderId,
  riderId,
  itemId,
  delivery_status
) =>
  API.put(`/weborders/assign_order_to_delivery_agent/${orderId}/${itemId}`, {
    delivery_status,
    riderId,
  });

// Assign Restaurant Order to Delivery Agent
export const assignRestaurantOrderToDeliveryAgentApi = (
  restaurantOrderId,
  riderId,
  delivery_status
) =>
  API.put(
    `/weborders/assign_restaurant_order_to_delivery_agent/${restaurantOrderId}`,
    {
      delivery_status,
      riderId,
    }
  );

// Assign Package Order to Delivery Agent
export const assignPackageOrderToDeliveryAgentApi = (
  packageOrderId,
  riderId,
  delivery_status
) =>
  API.put(
    `/weborders/assign_package_order_to_delivery_agent/${packageOrderId}`,
    {
      delivery_status,
      riderId,
    }
  );

// Mark ordered Items/cart as paid
export const markOrderAsPaidApi = (orderId) =>
  API.put(`/weborders/${orderId}/paid`);

// Verify seller listing
export const apiVerifySellerListing = (listingId, user_id, verifyListing) =>
  API.patch(`/posts/verify/${listingId}/${user_id}`, verifyListing);

// UnVerify seller listing
export const apiUnverifySellerListing = (listingId, user_id, verifyListing) =>
  API.patch(`/posts/unverify/${listingId}/${user_id}`, verifyListing);

// Delete a rider
export const deleteRiderApi = (rider_id, staff_id) =>
  API.delete(`/riders/delete-rider/${rider_id}/${staff_id}`);

// CRUD FOR RESTAURANT VENDOR RIDERS
export const getRestaurantRidersApi = (vendorId) =>
  API.get(`/riders/restaurant-vendor/${vendorId}/riders`);
export const createRestaurantRiderApi = (riderData, vendorId) =>
  API.post(`/riders/restaurant-vendor/${vendorId}/riders`, riderData);
export const updateRestaurantRiderApi = (riderData, vendorId, riderId) =>
  API.put(`/riders/restaurant-vendor/${vendorId}/riders/${riderId}`, riderData);
export const deleteRestaurantRiderApi = (vendorId, riderId) =>
  API.delete(`/riders/restaurant-vendor/${vendorId}/riders/${riderId}`);

// CRUD FOR RETAILER WHOLESALER RIDERS
export const getRetailerRidersApi = (vendorId) =>
  API.get(`/riders/retailer-wholesaler-vendor/${vendorId}/riders`);
export const createRetailerRiderApi = (riderData, vendorId) =>
  API.post(`/riders/retailer-wholesaler-vendor/${vendorId}/riders`, riderData);
export const updateRetailerRiderApi = (riderData, vendorId, riderId) =>
  API.put(
    `/riders/retailer-wholesaler-vendor/${vendorId}/riders/${riderId}`,
    riderData
  );
export const deleteRetailerRiderApi = (vendorId, riderId) =>
  API.delete(
    `/riders/retailer-wholesaler-vendor/${vendorId}/riders/${riderId}`
  );
// API calls for managing retailer and wholesaler sections
// Create a section
export const apiCreateRetailerWholesalerSection = (sectionData, userId) =>
  API.post(`/rw/rw-create-sections`, { ...sectionData, userId });

// Fetch sections
export const apiFetchRetailerWholesalerSections = (vendorId) =>
  API.get(`/rw/rw-get-sections?vendorId=${vendorId}`);

// Update a section
export const apiUpdateRetailerWholesalerSection = (
  sectionId,
  updatedSectionData
) => API.put(`/rw/rw-update-section/${sectionId}`, updatedSectionData);

// Delete a rw section
export const apiDeleteRetailerWholesalerSection = (sectionId) =>
  API.delete(`/rw/del-rw-section/${sectionId}`);

// Fetch retailers and wholesalers
export const apiFetchRetailerWholesalers = (vendorId, isSuperAdmin) =>
  API.get(
    `/rw/retailers-wholesalers?vendorId=${vendorId}&isSuperAdmin=${isSuperAdmin}`
  );

// Create a product
export const apiCreateRetailerWholesalerProduct = (productData) =>
  API.post(`/rw/create-vendor-product`, productData);

// Fetch product details
export const apiFetchRetailerWholesalerProductDetails = (productId) =>
  API.get(`/rw/rw-product/${productId}`);

// Update a product
export const apiUpdateRetailerWholesalerProduct = (
  productId,
  updatedProductData
) => API.put(`/rw/product/${productId}`, updatedProductData);

// Delete a product
export const apiDeleteRetailerWholesalerProduct = (productId) =>
  API.delete(`/rw/del-rw-product/${productId}`);

// Option Group API functions
export const apiRestaurantCreateOptionGroup = (optionGroupData) =>
  API.post(`/menu/create-restaurant-option-group`, optionGroupData);
export const apiFetchOptionGroupsRestaurant = (restaurantId) =>
  API.get(`/menu/get-restaurant-option-groups?restaurantId=${restaurantId}`); //Reused endpoint
export const apiUpdateOptionGroup = (optionGroupId, updatedOptionGroupData) =>
  API.patch(`/menu/option-group/${optionGroupId}`, updatedOptionGroupData);
export const apiDeleteOptionGroup = (optionGroupId) =>
  API.delete(`/menu/option/${optionGroupId}`);

// Option API functions
export const apiCreateRestaurantOption = (optionData) =>
  API.post(`/menu/create-restaurant-option`, optionData);
export const apiFetchOptionsRestaurants = (restaurantId) =>
  API.get(`/menu/restaurant-options/${restaurantId}`);
export const apiUpdateOption = (optionId, updatedOptionData) =>
  API.put(`/menu/option/${optionId}`, updatedOptionData);
export const apiDeleteOption = (optionId) =>
  API.delete(`/menu/del-option/${optionId}`);

// Retailer/Wholesaler Option Groups
// Retailer/Wholesaler Options
export const apiCreateRetailerWholesalerOption = (optionData) =>
  API.post("/menu/rwOptions", optionData);
export const apiFetchRetailerWholesalerOptions = (vendorId) =>
  API.get(`/menu/get-rwOptions/${vendorId}`);
export const apiUpdateRetailerWholesalerOption = (
  optionId,
  updatedOptionData
) => API.put(`/menu/rwOptions/${optionId}`, updatedOptionData);
export const apiDeleteRetailerWholesalerOption = (optionId) =>
  API.delete(`/menu/rwOptions/${optionId}`);

// Retailer/Wholesaler Option Groups
export const apiCreateRetailerWholesalerOptionGroup = (optionGroupData) =>
  API.post("/menu/rwOptionGroups", optionGroupData);
export const apiFetchRetailerWholesalerOptionGroups = (vendorId) =>
  API.get(`/menu/rwOptionGroups/${vendorId}`);
export const apiUpdateRetailerWholesalerOptionGroup = (
  optionGroupId,
  updatedOptionGroupData
) => API.put(`/menu/rwOptionGroups/${optionGroupId}`, updatedOptionGroupData);
export const apiDeleteRetailerWholesalerOptionGroup = (optionGroupId) =>
  API.delete(`/menu/rwOptionGroups/${optionGroupId}`);

export const apiDeleteRetailerWholesaler = (id) =>
  API.delete(`/retailer-wholesaler/${id}`);
